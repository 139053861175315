@use "sass:math";
@use "sass:list";

@import url("https://fonts.googleapis.com/css2?family=ABeeZee&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shantell+Sans&display=swap");

// Grayscale
$black-dark: #000000;
$black-regular: #1e1a1b;
$black-light: #2a282b;
$gray-dark: #353238;
$gray-regular: #807d84;
$gray-light: #cdcdcd;
$sliver-dark: #9e9b9b;
$silver-regular: #d8d8d8;
$silver-light: #f4f4f4;
$white: #ffffff;

// User interface
$yellow-regular: #ffde59;
$yellow-dark: #bf9e14;
$yellow-light: #fff9e6;

$pink-regular: #ff79f9;
$pink-dark: #fc20f2;
$pink-light: #ffeefe;

$orange-regular: #ff8c2c;
$orange-dark: #af4f00;
$orange-light: #fff7ef;

$green-regular: #3aa352;
$green-dark: #0c7022;
$green-light: #f1fcf1;

$sky-blue-regular: #00d0ff;
$sky-blue-dark: #006b84;
$sky-blue-light: #e4fdff;

$sky-blue-regular: #00acff;
$sky-blue-dark: #005b87;
$sky-blue-light: #f2faff;

$sidebar-width: 250px;

$title-font: "Shantell Sans", Roboto, sans-serif;
$text-font: "AbeeZee", Open Sans, sans-serif;

$border-radius: 40px;
$logo-height: 80px;
$default-font-size: 16px;

// TODO override Bootstrap colors

@mixin title-style($level, $style) {
  font-family: $title-font;

  // Adapt the color according to the title style
  @if $style == "light" {
    color: $white;
  } @else if $style == "yellow" {
    color: $yellow-regular;
  } @else {
    color: $black-regular;
  }

  // Adapt the font size and weight according to the title level
  @if $level == 1 {
    font-size: 42px;
    font-weight: 900;
  } @else if $level == 2 {
    font-size: 32px;
    font-weight: 800;
  } @else if $level == 3 {
    font-size: 20px;
    font-weight: 700;
  } @else if $level == 4 {
    font-size: 18px;
    font-weight: 600;
  }
}

@mixin text-style($text-style) {
  // $text-style : error, warning, info, link
  font-family: $text-font;
  font-size: $default-font-size;

  @if $text-style == "link" {
    color: $sky-blue-dark;
  } @else if $text-style == "light" {
    color: $white;
  } @else {
    color: $black-regular;
  }
}

@mixin yellow-styled-hover() {
  background-color: $yellow-light;
  color: $yellow-dark;
  text-decoration: none;
}

@mixin content-width() {
  width: 75%;

  @media only screen and (max-width: 910px) {
    width: 95%;
  }
}

*,
p {
  @include text-style("");
}

a {
  @include text-style("link");
}
a:hover {
  color: $yellow-dark;
}

// Define default h1, h2, h3, h4
$title-styles: "dark", "light", "yellow";
@for $i from 1 through 4 {
  h#{$i},
  h#{$i} *,
  .h#{$i},
  .h#{$i} * {
    @include title-style($i, "dark");
  }

  // Define different title style classname
  // - .h1-light, .h1-dark, .h1-yellow
  // - ...
  @each $style in $title-styles {
    .h#{$i}-#{$style},
    .h#{$i}-#{$style} * {
      @include title-style($i, $style);
    }
  }
}

// Define different text style classname
// - .text, .text-light, .text-link
// - .subtitle, .subtitle-light, .subtitle-link
// - ...
$text-types: (
  "text": $default-font-size,
  "subtitle": 36px,
  "subheading": 24px,
  "caption": 14px,
  "quote": $default-font-size,
);
$text-styles: "", "light", "link", "dark";
@each $type, $size in $text-types {
  @each $style in $text-styles {
    .#{$type}-#{$style} {
      @include text-style($style);
      font-size: $size;
      border: none;
      background-color: transparent;

      @if $type == "quote" {
        font-style: italic;
      }
    }

    @if $style == "link" {
      .#{$type}-#{$style}:hover {
        color: $yellow-dark;
      }
    }
  }
}

input:focus,
button:focus {
  box-shadow: 0 0 0 0.25rem #{$yellow-regular}40;
  // outline-color: $yellow-regular;
}

.notion {
  .notion-page-header {
    display: none;
  }

  .notion-page-offset {
    margin-top: 20px;
  }

  .notion-title,
  .notion-title * {
    @include title-style(1, "dark");
  }

  @for $i from 1 through 4 {
    .notion-h#{$i},
    .notion-h#{$i} * {
      @include title-style($i + 1, "dark");
    }
  }
}

.App {
  max-width: 100vw;
  overflow: hidden;
}

.app-loader {
  background: $gray-dark;
  width: 100vw;
  height: 100vh;
}

.logo {
  height: $logo-height;
}

.layout {
  $margin-y: 5px;
  $navbar-height: calc($logo-height + $margin-y * 2);

  display: flex;

  .sidebar-menu {
    background-color: $gray-dark;
    height: 100vh;
    position: fixed;
    width: $sidebar-width;
    z-index: 10;

    .navbar {
      display: flex;
      flex-direction: column;
    }

    @media only screen and (min-width: 910px) {
      overflow: scroll; // Mobile menu won't display if this line is applied
    }
    @media only screen and (max-width: 910px) {
      height: $navbar-height;
      width: 100vh;

      .navbar {
        display: flex;
        flex-direction: row;
        padding: 0;
        width: 100vw;

        .navbar-toggler {
          color: $yellow-regular;
          border-color: $yellow-regular;
        }

        .navbar-toggler svg {
          fill: $yellow-regular;
          color: $yellow-regular;
          stroke: $yellow-regular;
        }

        .navbar-brand {
          padding: 0 5px;
        }

        .navbar-collapse {
          background-color: $gray-dark;
          max-height: calc(100vh - $logo-height);
          overflow: scroll;
        }
      }
    }

    .subject-menu {
      // width: 100%;

      ul {
        padding-left: 0;
      }

      .grade-name {
        text-align: center;
        background-color: $yellow-regular;
        padding: 5px;
        margin: 20px;
        border-radius: $border-radius;
        cursor: pointer;

        // TODO remove this when Bootstrap color are override
        .dropdown-toggle:hover {
          color: unset;
        }

        .dropdown-menu {
          background-color: $yellow-regular;
          border: none;
          width: 100%;
          padding: 10px;
          border-radius: $border-radius;

          a {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          @media only screen and (max-width: 910px) {
            // background-color: transparent;
          }

          .dropdown-item {
            text-align: center;
            padding: 20px;
            border-radius: $border-radius;
          }

          .dropdown-item.active,
          .dropdown-item:active,
          .dropdown-item:hover,
          .dropdown-item:hover a {
            @include yellow-styled-hover();
          }
        }
      }

      .link-item {
        display: flex;
        align-items: center;
        // padding: 10px;
        text-decoration: none;

        @media only screen and (min-width: 910px) {
          text-align: left;
          width: $sidebar-width;
        }

        a {
          width: 100%;
          display: flex;
          justify-content: start;
          align-items: center;
          text-decoration: none;
          @include title-style(3, "light"); // TODO Find a way to remove this

          @media only screen and (max-width: 910px) {
            justify-content: center;
          }
        }

        img {
          height: 3em;
          margin-right: 0.5em;
        }
      }

      .link-item:hover,
      .link-item:active,
      .link-item *:hover,
      .link-item *.active {
        @include yellow-styled-hover();
      }
    }
  }

  .content {
    $dektop-width: calc(100vw - $sidebar-width);

    margin-left: $sidebar-width;
    width: $dektop-width;
    background-color: $silver-light;

    .bg {
      width: $dektop-width;
      height: 100%;
      opacity: 0.1;
      background-image: url("bg.svg");
      position: fixed;
      z-index: 1;
    }

    .outlet-content {
      display: flex;
      justify-content: center;
      min-height: 100vh;
      position: relative;
      z-index: 9;
      padding: 20px;
    }

    @media only screen and (max-width: 910px) {
      $mobile-width: 100vw;

      margin-top: $navbar-height;
      margin-left: 0;
      width: $mobile-width;

      .bg {
        width: $mobile-width;
      }

      .outlet-content {
        min-height: calc(100vh - $logo-height);
      }
    }
  }
}

.home-layout {
  .public-header {
    background-color: $gray-dark;
  }
}

.courses-list {
  width: 100%;

  .subject-title {
    display: flex;
    align-items: center;
    margin-top: 1em;

    .subject-image {
      height: 5em;
      margin-right: 0.5em;
    }
  }

  .container {
    .search-input {
      @include content-width();

      input,
      button {
        border: none;
        background: $white;
      }

      input:focus,
      button:focus {
        box-shadow: 0 0 0 0.25rem #{$yellow-regular}40;
        // outline-color: $yellow-regular;
      }
    }

    .courses-list-content {
      @include content-width();

      .courses-card {
        margin-bottom: 20px;

        .card {
          background: $gray-dark;
          padding: 30px;
          border-radius: $border-radius;

          .card-header {
            border-bottom: 1px solid #{$yellow-regular};
          }

          .list-group a {
            text-decoration: none;
          }
          .list-group .list-group-item {
            background-color: $gray-dark;
            padding: 20px;
            text-decoration: none;
            border: none;
            // border-radius: $border-radius;

            @media only screen and (max-width: 910px) {
              padding: 20px 0px;
            }
          }
          .list-group a:hover,
          .list-group .list-group-item:hover {
            @include yellow-styled-hover();
          }
        }
      }
    }
  }
}

.course-content {
  @media only screen and (max-width: 910px) {
    max-width: calc(100vw - 2 * 20px);
  }

  .btn-back {
    background-color: transparent;
    border: none;
  }

  .course-container {
    background-color: $white;
    padding: 40px;
    border-radius: $border-radius;

    @media only screen and (max-width: 910px) {
      padding: 20px;
    }
  }
}
